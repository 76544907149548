import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'
import { UserContext } from 'state/user-context'
import ExportExcelButton from '../ExportExcelButton'
import ModalUploadClusterDePrecos from '../ModalUpload'

const Header: React.FC = () => {
  const toast = useToast()
  const { userParameters } = useContext(UserContext)
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [name, setName] = useState('')
  const [factory, setFactory] = useState<IOption>({} as IOption)
  const [factoryOpt, setFactoryOpt] = useState<IOption[]>([])

  useEffect(() => {
    setName('')
    setFactory({} as IOption)
  }, [isOpen])

  const onCreate = useCallback(() => {
    if (!name || !factory) {
      return toast({
        title: 'Todos os campos são obrigatórios.',
        status: 'error',
        isClosable: true,
      })
    }

    const request = { name, factory_code: factory.value }

    api
      .post('/clusters', request)
      .then(() => {
        onClose()
        toast({
          title: 'Adicionado com sucesso!',
          status: 'success',
          isClosable: true,
        })
        setTimeout(() => window.location.reload(), 2000)
      })
      .catch(() => {
        toast({
          title: 'Erro ao adicionar, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
  }, [factory, name, onClose, toast])

  const getFactory = useCallback(() => {
    isOpen &&
      api.get('/factories').then((res) => {
        setFactoryOpt(
          res.data.data.factories.map((v: any) => ({
            label: v.name,
            value: v.id,
          }))
        )
      })
  }, [isOpen])
  useEffect(getFactory, [getFactory])

  return (
    <>
      <ExportExcelButton type="Cluster de Preço" />
      <ModalUploadClusterDePrecos />
      <Button disable={!userParameters.write} onClick={onOpen}>
        Adicionar
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text>Filial</Text>
              <Autocomplete
                value={factory}
                options={factoryOpt}
                setValue={setFactory}
              />
              <Text pt="1rem">Nome do Cluster</Text>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onCreate}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Header
