import { useState } from 'react'

import Body from './Body'
import Header from './Header'

const RelatividadeCanais: React.FC<any> = () => {
  const [rows, setRows] = useState<any[]>([])
  const [clusterId, setClusterId] = useState('0')

  return (
    <>
      <Header setRows={setRows} setClusterId={setClusterId} clusterId={clusterId}/>
      <Body rows={rows} setRows={setRows} clusterId={clusterId} />
    </>
  )
}

export default RelatividadeCanais
