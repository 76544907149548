import { useRef, useState } from 'react'
import { Box, Spinner, useToast } from '@chakra-ui/react'
import { SplitButton } from 'primereact/splitbutton'
import api from 'services/api'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  url: string
  type: string
  filter: IFilterProps
  sceneryId: string
  idsToExport?: number[]
  orderBy: string
  sort: string
}

export interface IFilterProps {
  scenery_id?: number
  channel_code?: number[]
  cluster_id?: number[]
  level1_code?: string[]
  level2_code?: string[]
  level3_code?: string[]
  level4_code?: string[]
  level5_code?: string[]
  client_code?: number[]
  classificacao_item?: string[]
}

const ExportExcelButton: React.FC<IProps> = ({
  url,
  type,
  filter,
  sceneryId,
  idsToExport = [],
  orderBy,
  sort,
}) => {
  const [loadingExport, setLoadingExport] = useState<boolean>(false)
  const toast = useToast()
  const linkRef = useRef<any>(null)
  const loadingRef = useRef<any>(null)

  const onClick = (extensionType: string) => {
    setLoadingExport(true)
    toast({
      title: `Aguarde. Estamos preparando o download`,
      status: 'info',
    })
    const request: { [key: string]: string[] } = {}

    Object.keys(filter).forEach((key) => {
      if (key in filter) {
        // Verifica se a chave existe no objeto antes de acessá-la
        request[key as keyof IFilterProps] =
          (filter[key as keyof IFilterProps] as unknown as IOption[])?.map(
            (v: IOption) => v.value
          ) || []
      }
    })

    const newUrl = type === 'Tabela Visão Cliente' ? `${url}-client` : url
    api
      .post(
        newUrl,
        {
          filter: { ...request, scenery_id: sceneryId },
          type,
          extensionType,
          idsToExport: type === 'Tabela Visão Cliente' ? [] : idsToExport,
          orderBy,
          sort,
        },
        { responseType: 'arraybuffer' } // Necessário para lidar com arquivos binários
      )
      .then((response) => {
        toast({
          title: `Download concluido.`,
          status: 'success',
        })
        setLoadingExport(false)
        if (type === 'Tabela Visão Cliente') {
          if (!response.data || response.data.byteLength === 0) {
            throw new Error('Arquivo vazio ou inválido.')
          }
          setLoadingExport(false)

          const blob = new Blob([response.data], { type: 'application/zip' })
          const url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Cenarios_${type}_${sceneryId}.zip`)

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          let mimeType = 'application/vnd.ms-excel;'
          let fileExtension = 'xls'

          if (extensionType === 'csv') {
            mimeType = 'text/csv;charset=utf-8;'
            fileExtension = 'csv'
          }

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: mimeType })
          )

          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Cenário_${type}_${sceneryId}.${fileExtension}`
          )

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
      .catch((err) => {
        setLoadingExport(false)
        console.error('Erro no download:', err)
        toast({
          title: `Erro ao gerar o download: ${err.message}`,
          status: 'error',
          isClosable: true,
        })
      })
  }

  const items = [
    {
      label: 'Exportar CSV',
      icon: 'pi pi-file',
      command: () => onClick('csv'),
    },
    // {
    //   label: 'Exportar XLSX',
    //   icon: 'pi pi-file-excel',
    //   command: () => onClick('xls'),
    // },
  ]

  return (
    <>
      <Box position="relative" display="inline-block">
        {/* SplitButton */}
        <SplitButton
          disabled={loadingExport}
          label={loadingExport ? '' : 'Exportar'}
          icon={loadingExport ? '' : 'pi pi-download'}
          model={items}
          className="p-button-success"
          style={{
            backgroundColor: '#003b74',
            color: '#fff',
            height: 33,
            paddingLeft: 10,
            borderRadius: 5,
            fontSize: '1.1rem',
            fontWeight: 100,
            width: 120,
          }}
        />
        {/* Spinner */}
        {loadingExport && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={1}
          >
            <Spinner size="sm" color="white" />
          </Box>
        )}
      </Box>

      {/* Hidden download link */}
      <a ref={linkRef} style={{ display: 'none' }} download />

      {/* Loading modal */}
      <LoadingModal ref={loadingRef} />
    </>
  )
}

export default ExportExcelButton
