import { useCallback, useContext, useEffect, useState } from 'react'

import api from 'services/api'
import Paper from 'components/Paper'
import Autocomplete from 'components/Autocomplete'

import { columns } from './constants'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { UserContext } from 'state/user-context'
import ModalUploadRelatividadeCanais from './ModalUpload'
import ExportExcelButton from './ExportExcelButton'

interface IProps {
  setRows: React.Dispatch<React.SetStateAction<any[]>>
  setClusterId: React.Dispatch<React.SetStateAction<string>>
  clusterId: string
}

const Header: React.FC<IProps> = ({ setRows, setClusterId, clusterId }) => {
  const { user } = useContext(UserContext)
  const [cluster, setCluster] = useState({ label: 'TODOS', value: '0' })
  const [clusterOpt, setClusterOpt] = useState<any>([])

  useEffect(() => {
    setClusterId(cluster.value)
  }, [cluster, setClusterId])

  const getSku = useCallback(() => {
    api.get('/clusters/all').then((res) => {
      if (user?.clusters?.includes(0)) {
        setClusterOpt(
          res.data.data.clusters.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        )
      } else {
        setClusterOpt(
          res.data.data.clusters
            .filter((item: any) => user?.clusters?.includes(item.id))
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))
        )
      }
    })
  }, [user])

  useEffect(getSku, [getSku])

  const getInfo = useCallback(() => {
    setRows([])

    api.get(`/relativity-channels/${cluster.value}`).then((res) => {
      setRows(res.data.data.channels)
    })
  }, [cluster, setRows])
  useEffect(getInfo, [getInfo])

  return (
    <Paper
      containerStyle={{
        padding: '2rem',
        marginBottom: '2rem',
        overflow: 'visible',
      }}
    >
      <HStack
        spacing={4}
        py="1rem"
        pl="1rem"
        mb="1rem"
        color="#003b74"
        bgColor="#f2f3f5"
        borderRadius="4px"
      >
        {columns.map((column) => (
          <Box w="14rem" key={column.Header}>
            {column.Header}
          </Box>
        ))}
      </HStack>
      <Flex justifyContent="space-between" pl="1rem">
        <Box w="14rem" style={{ zIndex: 99 }}>
          <Autocomplete
            value={cluster}
            options={clusterOpt}
            setValue={setCluster}
          />
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
          <ExportExcelButton
            type="Relatividade de Canais"
            clusterId={clusterId}
          />
          <ModalUploadRelatividadeCanais />
        </div>
        {/* <Box w="14rem">
          <Autocomplete value={sku} options={skuOpt} setValue={setSku} />
        </Box> */}
      </Flex>
    </Paper>
  )
}

export default Header
