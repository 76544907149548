import { useCallback } from 'react'

interface IProps {
  value: string | number
}

const MoneyYear: React.FC<IProps> = ({ value }) => {
  const localeNumber = useCallback((value: string) => {
    let newVal = !value
      ? '0,00'
      : Intl.NumberFormat('pt-BR').format(+value.replace(',', '.'))
    if (!newVal.includes(',')) newVal += ''
    if (newVal.split(',')[1]?.length === 1) newVal += '0'
    if (newVal.split(',')[1]?.length > 2) {
      return `${newVal.split(',')[0]}}`
    } else return newVal.split(',')[0]
  }, [])

  if (value === '-') return <>{value}</>
  return <>{value && `R$ ${localeNumber(value + '')}`}</>
}

export default MoneyYear
