import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Divider,
  Flex,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import api from 'services/api'
import { DataTable, SortOrder } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import Money from '../Money'
import Percent from '../Percent'

interface CompetitorListModalProps {
  isOpen: boolean
  row: any
  onClose: () => void
}

export interface ICompetitor {
  channel_code: string
  cluster_id: string
  descolamento_atual: string | number
  descolamento_preco_proposto: string | number
  description: string
  description_priority: number
  regular_price: string | number
  scenery_id: string
  sku: string
}

const CompetitorListModal: React.FC<CompetitorListModalProps> = ({
  isOpen,
  row,
  onClose,
}) => {
  const toast = useToast()
  const cancelRef = useRef<null>(null)
  const [sort, setSort] = useState<SortOrder>(1)
  const [orderBy, setOrderBy] = useState('description_priority')
  const [dataTable, setDataTable] = useState([])

  const getCompetitorBySku = async () => {
    const correctSku =
      row?.field === 'preco_concorrencia_consumidor'
        ? row.captain_code
        : row.sku
    await api
      .get(
        `/cluster-competitor/get-competitor-by-sku?sku=${correctSku}&scenery_id=${row.scenery_id}&cluster_id=${row.cluster_id}&channel_code=${row.channel_code}`
      )
      .then((res) => {
        if (res.data.data.length < 1) {
          toast({
            status: 'info',
            title: 'Não há concorrente para esse SKU',
          })
        }
        setDataTable(
          res.data.data.map((item: ICompetitor) => {
            return {
              ...item,
              descolamento_atual: +item.descolamento_atual,
              descolamento_preco_proposto: +item.descolamento_preco_proposto,
              regular_price: +item.regular_price,
            }
          })
        )
      })
      .catch((err) => {
        toast({
          status: 'error',
          title: 'Houve um erro ao procurar concorrentes para esse SKU',
        })
        console.log('Houve um erro:', err)
      })
  }

  useEffect(() => {
    if (row.sku) getCompetitorBySku()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  // const onDelete = useCallback(() => {
  //   console.log('quero deletar')
  //   api
  //     .delete(`/sceneries/${row.id}/${row.type}/delete`)
  //     .then(() => {
  //       onClose()
  //       toast({
  //         title: 'Cenário excluido com sucesso!',
  //         status: 'success',
  //         isClosable: true,
  //       })
  //     })
  //     .catch((error: any) => {
  //       toast({
  //         title: `Erro ao deletar o capitão: ${error.message} `,
  //         status: 'error',
  //         isClosable: true,
  //       })
  //     })
  // }, [row.id, toast, onClose, row.type])

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)

    setDataTable((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return sort === 1 ? 1 : -1
        if (bValue == null) return sort === 1 ? -1 : 1

        if (aValue < bValue) return sort === 1 ? -1 : 1
        if (aValue > bValue) return sort === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setSort(sort === 1 ? -1 : 1)
  }

  const dateTemplate = (rowData: any) => {
    return moment(rowData.created_at).format('DD/MM/yyyy')
  }

  const MoneyTemplate = (rowData: any) => {
    return rowData.regular_price ? <Money value={rowData.regular_price} /> : '-'
  }

  const PercentTemplate = (rowData: any, field: string) => {
    return rowData[field] ? (
      <Percent value={!rowData[field] ? '0,0' : rowData[field]} />
    ) : (
      '-'
    )
  }

  const verifiedDataTable = row?.field?.includes('geral')
    ? dataTable.filter((item: any) => item.description_priority !== 1)
    : dataTable.filter((item: any) => item.description_priority === 1)

  return (
    <>
      <Modal
        w="71rem"
        maxHeight={700}
        isOpen={isOpen}
        title="Dados de Pesquisa"
        onClose={onClose}
        body={
          <VStack w="100%" align="flex-start">
            <Divider />
            <DataTable
              onSort={onSort}
              scrollable
              scrollHeight="51vh"
              rows={15}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ width: '68rem' }}
              value={verifiedDataTable}
              sortField={orderBy}
              // selectionMode="multiple"
              // selection={selectedRows}
              // onSelectionChange={onSelectionChange}
              dataKey="id"
              emptyMessage="Sem dados"
            >
              {/* <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="created_at"
                header="Data"
                body={dateTemplate}
                style={{ minWidth: '100px', zIndex: '2' }}
                frozen
              ></Column> */}

              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="description"
                header="Concorrente"
                style={{ minWidth: '300px', zIndex: '2' }}
                frozen
                sortable
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="description_priority"
                header="Prioridade"
                style={{ minWidth: '80px', zIndex: '2' }}
                frozen
                sortable
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="regular_price"
                header="Preço"
                body={MoneyTemplate}
                style={{ minWidth: '80px', zIndex: '2' }}
                frozen
                sortable
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="descolamento_atual"
                header="Desc. BB Preço Atual"
                body={(rowData: any) =>
                  PercentTemplate(rowData, 'descolamento_atual')
                }
                style={{ minWidth: '100px', zIndex: '2' }}
                frozen
                sortable
              ></Column>
              <Column
                headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
                field="descolamento_preco_proposto"
                header="Desc. BB Preço Proposto"
                body={(rowData: any) =>
                  PercentTemplate(rowData, 'descolamento_preco_proposto')
                }
                style={{ minWidth: '80px', zIndex: '2' }}
                frozen
                sortable
              ></Column>
            </DataTable>
          </VStack>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
            </Flex>
          </VStack>
        }
      />
    </>
  )
}

export default CompetitorListModal
