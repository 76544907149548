const styles = (minWidth: any, backgroundColor?: any, color?: string) => ({
  headerStyle: {
    backgroundColor: backgroundColor ? backgroundColor : '#d0e1fd',
    color: '#003b74',
    padding: '1px 5px',
  },
  minwidth: `${minWidth}px`,
  bodyStyle: { padding: '1px', borderBottom: 'solid 1px #e5e5e5', color: '#000' },
})

export const colsSimple = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(50),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(250),
    frozen: true,
  },
  {
    field: 'quantidade_embalagem',
    header: 'Peso por embalagem',
    style: styles(70),
    frozen: false,
  },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto kg (tabela atual)',
    style: styles(120, '#8ee88e', '#fff'),
  },
  {
    field: 'preco_bruto_embalagem_tabela_atual',
    header: 'Preço bruto embalagem (tabela atual)',
    style: styles(110, '#8ee88e', '#fff'),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta sobre preço bruto (tabela atual)',
    style: styles(130, '#8ee88e', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto proposto (Kg)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao_embalagem',
    header: 'Preço bruto proposto (embalagem)',
    style: styles(120, '#d3f2c9 ', '#fff'),
  }, 
  {
    field: 'margem_bruta_sobre_receita_bruta_atual',
    header: 'Mrg. bruta sobre receita bruta proposto',
    style: styles(140, '#d3f2c9', '#fff'),
  },
  {
    field: 'aumento_reducao_atual',
    header: 'Aumento ou redução proposto',
    style: styles(120, '#d3f2c9', '#fff'),
  },
  {
    field: 'ppc_atual',
    header: 'Preço mercado atual',
    style: styles(80, '#b1d5f2 ', '#fff'),
  },  
  {
    field: 'preco_mercado_novo',
    header: 'Preço mercado proposto (KG)',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_ppc_atual',
    header: 'Descolamento preço atual mercado X preço proposto',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrente_p1',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(150, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_p1',
    header: 'Descolamento do preço proposto em relação a concorrência P1',
    style: styles(190, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor outros (kg)',
    style: styles(150, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_geral',
    header: 'Descolamento do preço proposto em relação a concorrência outros',
    style: styles(200, '#b1d5f2 ', '#fff'),
  },
]

export const colsDetailed = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(50),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(250),
    frozen: true,
  },
  {
    field: 'quantidade_embalagem',
    header: 'Peso por Embalagem',
    style: styles(70),
    frozen: false,
  },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'tributacao',
    header: 'Tributação',
    style: styles(80),
  },
  { field: 'frete', header: 'Frete (Kg)', style: styles(80) },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto (Kg)',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete (Kg)', style: styles(80) },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência P1 do capitão (kg)',
    style: styles(150, '#b1d5f2', '#fff'),
  },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(100, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_consumidor',
    header: 'Preço proposto ao consumidor c/ descolamento (kg)',
    style: styles(150, '#b1d5f2', '#fff'),
  },
  {
    field: 'relatividade_sku',
    header: 'Relatividade sku',
    style: styles(100, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_relativo_sku',
    header: 'Preço proposto ao consumidor relativo por SKU (Kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(80, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_s_mkp',
    header: 'Preço proposto s/ markup',
    style: styles(100, '#b1d5f2', '#fff'),
  },
  {
    field: 'mrg_bruta_meta',
    header: 'Mrg bruta meta sobre receita bruta',
    style: styles(130, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'pis_cofins_origem',
    header: 'Pis/cofins Origem',
    style: styles(80, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'icms_origem',
    header: 'Icms origem',
    style: styles(80, '#d3f2c9 ', '#fff'),
  },
  { field: 'ipi', header: 'Ipi', style: styles(70, '#d3f2c9 ', '#fff') },
  { field: 'mva', header: 'Mva', style: styles(70, '#d3f2c9 ', '#fff') },
  {
    field: 'icms_st',
    header: 'Icms st',
    style: styles(70, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_liquido_sugestao',
    header: 'Preço líquido proposto (Kg)',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'margem_bruta_sobre_receita_liquida',
    header: 'Mrg. bruta sobre receita líquida (preço proposto)',
    style: styles(160, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'lucro_bruto_sugestao',
    header: 'Lucro bruto (preço proposto)',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'relatividade_canal',
    header: 'Relatividade canal',
    style: styles(100, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto proposto (Kg)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao_embalagem',
    header: 'Preço bruto proposto (embalagem)',
    style: styles(100, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'aumento_reducao_atual',
    header: 'Aumento ou redução proposto',
    style: styles(100, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_cst',
    header: 'Preço bruto proposto s/ ST (Kg)',
    style: styles(100, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto kg (tabela atual)',
    style: styles(100, '#B4E5A2', '#003b74'),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta sobre preço bruto (tabela atual)',
    style: styles(150, '#B4E5A2', '#003b74'),
  },
  {
    field: 'preco_bruto_embalagem_tabela_atual',
    header: 'Preço bruto embalagem (tabela atual)',
    style: styles(120, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_atual',
    header: 'Lucro bruto sobre preço bruto (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_sobre_receita_bruta_sugestao',
    header: 'Lucro bruto sobre receita bruta (preço proposto KG) ',
    style: styles(160, '#d3f2c9', '#003b74'),
  },  
  {
    field: 'margem_bruta_sobre_receita_bruta_atual',
    header: 'Mrg. bruta sobre receita bruta proposto',
    style: styles(130, '#d3f2c9', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(80, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_mercado_novo',
    header: 'Preço mercado proposto (KG)',
    style: styles(130, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'ppc_atual',
    header: 'Preço mercado atual',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_ppc_atual',
    header: 'Descolamento preço atual mercado X preço proposto',
    style: styles(170, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrente_p1',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(170, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_p1',
    header: 'Descolamento do preço proposto em relação a concorrência P1',
    style: styles(190, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor outros (kg)',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_geral',
    header: 'Descolamento do preço proposto em relação a concorrência outros',
    style: styles(200, '#b1d5f2 ', '#fff'),
  },   
  {
    field: 'level1_name',
    header: 'Nível 1',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level2_name',
    header: 'Nível 2',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level3_name',
    header: 'Nível 3',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level4_name',
    header: 'Nível 4',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level5_name',
    header: 'Nível 5',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
]

export const colsDetailedChildren = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(350),
    frozen: true,
  },
  {
    field: 'quantidade_embalagem',
    header: 'Peso por Embalagem',
    style: styles(70),
    frozen: false,
  },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'tributacao',
    header: 'Tributação',
    style: styles(120),
  },
  { field: 'frete', header: 'Frete (Kg)', style: styles(120) },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto (Kg)',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete (Kg)', style: styles(120) },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência P1 do capitão (kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(120, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_consumidor',
    header: 'Preço proposto ao consumidor c/ descolamento (kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'relatividade_sku',
    header: 'Relatividade sku',
    style: styles(120, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_relativo_sku',
    header: 'Preço proposto ao consumidor relativo por SKU (Kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(120, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_s_mkp',
    header: 'Preço proposto s/ markup',
    style: styles(170, '#b1d5f2', '#fff'),
  },
  {
    field: 'mrg_bruta_meta',
    header: 'Mrg bruta meta sobre receita bruta',
    style: styles(170, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'pis_cofins_origem',
    header: 'Pis/cofins Origem',
    style: styles(80, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'icms_origem',
    header: 'Icms origem',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  { field: 'ipi', header: 'Ipi', style: styles(120, '#d3f2c9 ', '#fff') },
  { field: 'mva', header: 'Mva', style: styles(120, '#d3f2c9 ', '#fff') },
  {
    field: 'icms_st',
    header: 'Icms st',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_liquido_sugestao',
    header: 'Preço líquido proposto (Kg)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'margem_bruta_sobre_receita_liquida',
    header: 'Mrg. bruta sobre receita líquida (preço proposto)',
    style: styles(150, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'lucro_bruto_sugestao',
    header: 'Lucro bruto (preço proposto)',
    style: styles(150, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'relatividade_canal',
    header: 'Relatividade canal',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto proposto (Kg)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao_embalagem',
    header: 'Preço bruto proposto (embalagem)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'aumento_reducao_atual',
    header: 'Aumento ou redução proposto',
    style: styles(120, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_cst',
    header: 'Preço bruto proposto s/ ST (Kg)',
    style: styles(160, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto kg (tabela atual)',
    style: styles(160, '#B4E5A2', '#003b74'),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta sobre preço bruto (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'preco_bruto_embalagem_tabela_atual',
    header: 'Preço bruto embalagem (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_atual',
    header: 'Lucro bruto sobre preço bruto (tabela atual)',
    style: styles(170, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_sobre_receita_bruta_sugestao',
    header: 'Lucro bruto sobre receita bruta (preço proposto KG) ',
    style: styles(170, '#B4E5A2', '#003b74'),
  },  
  {
    field: 'margem_bruta_sobre_receita_bruta_atual',
    header: 'Mrg. bruta sobre receita bruta proposto',
    style: styles(170, '#d3f2c9', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(120, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_mercado_novo',
    header: 'Preço mercado proposto (KG)',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'ppc_atual',
    header: 'Preço mercado atual',
    style: styles(130, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_ppc_atual',
    header: 'Descolamento preço atual mercado X preço proposto',
    style: styles(180, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrente_p1',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_p1',
    header: 'Descolamento do preço proposto em relação a concorrência P1',
    style: styles(200, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor outros (kg)',
    style: styles(180, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_geral',
    header: 'Descolamento do preço proposto em relação a concorrência outros',
    style: styles(220, '#b1d5f2 ', '#fff'),
  },   
  {
    field: 'level1_name',
    header: 'Nível 1',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level2_name',
    header: 'Nível 2',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level3_name',
    header: 'Nível 3',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level4_name',
    header: 'Nível 4',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level5_name',
    header: 'Nível 5',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
]

export const colsTradingCondition = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(50),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(70),
    frozen: true,
  },
  {
    field: 'client_code',
    header: 'Código Cliente',
    style: styles(70),
    frozen: true,
  },
  {
    field: 'client_name',
    header: 'Nome Cliente',
    style: styles(290),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(80),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(250),
    frozen: true,
  },
  {
    field: 'quantidade_embalagem',
    header: 'Peso por Embalagem',
    style: styles(70),
    frozen: false,
  },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'tributacao',
    header: 'Tributação',
    style: styles(80),
  },
  { field: 'frete', header: 'Frete (Kg)', style: styles(80) },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto (Kg)',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete (Kg)', style: styles(100) },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência P1 do capitão (kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(120, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_consumidor',
    header: 'Preço proposto ao consumidor c/ descolamento (kg)',
    style: styles(150, '#b1d5f2', '#fff'),
  },
  {
    field: 'relatividade_sku',
    header: 'Relatividade sku',
    style: styles(100, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_relativo_sku',
    header: 'Preço proposto ao consumidor relativo por SKU (Kg)',
    style: styles(160, '#b1d5f2', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(80, '#b1d5f2', '#fff'),
  },
  {
    field: 'preco_alvo_s_mkp',
    header: 'Preço proposto s/ markup',
    style: styles(100, '#b1d5f2', '#fff'),
  },
  {
    field: 'mrg_bruta_meta',
    header: 'Mrg bruta meta sobre receita bruta',
    style: styles(130, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'pis_cofins_origem',
    header: 'Pis/cofins Origem',
    style: styles(80, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'icms_origem',
    header: 'Icms origem',
    style: styles(80, '#d3f2c9 ', '#fff'),
  },
  { field: 'ipi', header: 'Ipi', style: styles(60, '#d3f2c9 ', '#fff') },
  { field: 'mva', header: 'Mva', style: styles(60, '#d3f2c9 ', '#fff') },
  {
    field: 'icms_st',
    header: 'Icms st',
    style: styles(60, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_liquido_sugestao',
    header: 'Preço líquido proposto (Kg)',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'margem_bruta_sobre_receita_liquida',
    header: 'Mrg. bruta sobre receita líquida (preço proposto)',
    style: styles(130, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'lucro_bruto_tabela_sugerida',
    header: 'Lucro bruto (preço proposto)',
    style: styles(130, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'relativ_canal',
    header: 'Relatividade canal',
    style: styles(100, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto proposto (Kg)',
    style: styles(140, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'preco_embalagem_sugestao',
    header: 'Preço bruto proposto (embalagem)',
    style: styles(120, '#d3f2c9 ', '#fff'),
  },
  {
    field: 'aumento_reducao_atual',
    header: 'Aumento ou redução proposto',
    style: styles(100, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_cst',
    header: 'Preço bruto proposto s/ ST (Kg)',
    style: styles(120, '#d3f2c9', '#fff'),
  },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto kg (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta sobre preço bruto (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'preco_embalagem_atual',
    header: 'Preço bruto embalagem (tabela atual)',
    style: styles(140, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_atual',
    header: 'Lucro bruto sobre preço bruto (tabela atual)',
    style: styles(170, '#B4E5A2', '#003b74'),
  },
  {
    field: 'lucro_bruto_sobre_receita_bruta',
    header: 'Lucro bruto sobre receita bruta (preço proposto KG)',
    style: styles(180, '#d3f2c9', '#fff'),
  },
  {
    field: 'margem_bruta_sobre_receita_bruta',
    header: 'Mrg. bruta sobre receita bruta',
    style: styles(170, '#d3f2c9', '#fff'),
  },
  {
    field: 'mkp_da_cadeia',
    header: 'Markup da cadeia',
    style: styles(80, '#d3f2c9', '#fff'),
  },

  {
    field: 'novo_ppc_c_mkp',
    header: 'Preço mercado proposto (KG)',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'ppc_atual',
    header: 'Preço mercado atual',
    style: styles(110, '#b1d5f2 ', '#fff'),
  },

  {
    field: 'descolamento_ppc_atual',
    header: 'Descolamento preço atual mercado X preço proposto',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrente_p1',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'decolamento_real',
    header: 'Descolamento do preço proposto em relação a concorrência P1',
    style: styles(190, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor outros (kg)',
    style: styles(180, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'descolamento_concorrencia_geral',
    header: 'Descolamento do preço proposto em relação a concorrência outros',
    style: styles(220, '#b1d5f2 ', '#fff'),
  },
  { field: 'acoes_comerciais', header: 'Ações comerciais', style: styles(100, '#b1d5f2 ', '#fff') },
  {
    field: 'acoes_comerciais_anual',
    header: 'Ações Comerciais últimos 12 meses',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  { field: 'bonificacoes', header: 'Bonificações', style: styles(120, '#b1d5f2 ', '#fff') },
  { field: 'comissoes', header: 'Comissões', style: styles(120, '#b1d5f2 ', '#fff') },
  { field: 'descontos_fixos', header: 'Descontos fixo', style: styles(120, '#b1d5f2 ', '#fff') },
  {
    field: 'descontos_variaveis',
    header: 'Descontos variáveis',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'faturamento_anual',
    header: 'Faturamento últimos 12 meses',
    style: styles(110, '#b1d5f2 ', '#fff'),
  },  
  {
    field: 'margem_contribuicao_atual',
    header: 'Margem contribuição sobre preço bruto atual ',
    style: styles(180, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'cash_mc_atual',
    header: 'Cash MC sobre preço bruto atual',
    style: styles(150, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'margem_contribuicao',
    header: 'Margem contribuição',
    style: styles(110, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'cash_mc_proposto',
    header: 'Cash MC sobre preço bruto proposto',
    style: styles(160, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level1_name',
    header: 'Nível 1',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level2_name',
    header: 'Nível 2',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level3_name',
    header: 'Nível 3',
    style: styles(100, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level4_name',
    header: 'Nível 4',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
  {
    field: 'level5_name',
    header: 'Nível 5',
    style: styles(120, '#b1d5f2 ', '#fff'),
  },
]
