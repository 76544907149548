import React, { useCallback, useContext, useState } from 'react'
import { Input, InputGroup, InputLeftAddon, useToast } from '@chakra-ui/react'

import api from '../../../../../../services/api'
import { money } from '../../../../../../utils/masks'

import { IProps } from '../../types/IRow'
import { UserContext } from 'state/user-context'

const Priceppc: React.FC<IProps> = ({ row, getRows = () => null }) => {
  const { userParameters } = useContext(UserContext)
  const [value, setValue] = useState<string>(row?.new_price_ppc)
  const toast = useToast()

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(money(e.target.value))
  }, [])

  const onBlur = useCallback(() => {
    const url = '/politics'
    const req = {
      cluster_id: row?.cluster_id,
      captain_code: row?.captain_code,
      id: row?.id,
      new_price_ppc: Number(value),
    }
    api
      .put(url, req)
      .then(() => {
        toast({
          status: 'success',
          title: 'Atualização efetuada com sucesso',
        })
        getRows()
        setTimeout(() => {
          setValue('')
        }, 2000)
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro, tente novamente.',
        })
        setValue(row?.new_price_ppc)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, value, toast])

  return (
    <InputGroup w="8rem">
      <InputLeftAddon>R$</InputLeftAddon>
      <Input
        minW="6rem"
        type="number"
        value={!value ? '' : Number(value).toFixed(2)}
        onBlur={onBlur}
        onChange={onChange}
        disabled={!userParameters.write || !row?.is_pricepoint}
      />
    </InputGroup>
  )
}

export default Priceppc
