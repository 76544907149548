import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import TableBackend from 'components/TableBackend'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'
import Options from './Options'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Input from './Input'
import Paper from 'components/Paper'
import { Flex } from '@chakra-ui/react'
import ExportExcelButton from './ExportExcelButton'
import ModalUploadPlanoDeMetas from './ModalUpload'

interface IProps {
  rows: IRow[]
  year: number
  setYear: any
  onRefresh: () => void
}

const Table: React.FC<IProps> = ({ rows, year, setYear, onRefresh }) => {
  const [data, setData] = useState<IRow[]>([])
  const [order, setOrder] = useState(1)

  useEffect(() => {
    if (rows) setData(rows)
  }, [rows])

  const onSort = (event: any) => {
    const { sortField } = event

    setData((prevData: IRow[]) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  const priceTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        value={rowData.average_price}
        param="average_price"
        onUpdate={onRefresh}
      />
    )
  }

  const billingTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        value={rowData.monthly_billing}
        param="monthly_billing"
        onUpdate={onRefresh}
      />
    )
  }

  return (
    <Paper containerStyle={{ width: '100%' }}>
      <Flex marginBottom={5} justify="right">
        <div style={{ gap: 20, display: 'flex', flexDirection: 'row' }}>
          <ExportExcelButton type="Plano de Metas" />
          <ModalUploadPlanoDeMetas />
        </div>
      </Flex>
      <DataTable
        onSort={onSort}
        scrollable
        scrollHeight="flex"
        // paginator
        rows={rows?.length}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={data}
        dataKey="id"
        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="month"
          header="Mês"
          style={{ maxWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="monthly_billing"
          header="Faturamento Bruto"
          body={billingTemplate}
          style={{ maxWidth: '80px', zIndex: '2' }}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="average_price"
          header="Preço Médio"
          body={priceTemplate}
          style={{ maxWidth: '80px', zIndex: '2' }}
        ></Column>
        {/* <Column
        field=""
        header=""
        body={priceTemplate}
        style={{ minWidth: '80px', zIndex: '2' }}
      ></Column> */}
      </DataTable>
    </Paper>
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
