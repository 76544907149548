import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import api from 'services/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from 'state/user-context'
import { UploadButton } from 'pages/ParametersEdit/Header/PrecosDeMercadoUpload/styles'
import LoadingModal from 'components/LoadingModal'
import Button from 'components/Button'
import { FaCloudUploadAlt } from 'react-icons/fa'

const ModalUploadClusterDePrecos: React.FC = () => {
  const loadingRef = useRef<any>(null)
  const { user } = useContext(UserContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const handleUpload = useCallback(
    (formData: FormData) => {
      openLoading(
        'Fazendo upload...',
        api
          .post(`/cluster-bundles/upload`, formData)
          .then(() => {
            toast({
              title: 'Upload realizado com sucesso.',
              status: 'success',
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch((error) => {
            toast({
              title: `${error.response.status} - Erro ao fazer upload: verifique o arquivo e tente novamente.`,
              status: 'error',
            })
          })
      )
    },
    [openLoading, toast]
  )

  const onUpload = useCallback(
    (e: any) => {
      const fileName = e.target?.files?.[0]?.name

      if (!fileName || !/\.(xls|xlsx)$/i.test(fileName)) {
        return toast({
          title:
            'Arquivo inválido. Por favor envie no formato excel (.xls ou .xlsx).',
          status: 'error',
        })
      }
      const file = e.target.files[0]

      if (file.size >= 4e7) {
        return toast({
          title: 'Arquivo inválido. Limite 40mb.',
          status: 'error',
        })
      }

      const formData = new FormData()

      formData.append('file', file)

      handleUpload(formData)
    },
    [handleUpload, toast]
  )

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: '#003b74',
            // backgroundColor: '#003b74' || '#38A169',
          }}
        >
          <FaCloudUploadAlt style={{ marginRight: '0.5rem' }} />
          Importar
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="35rem">
          <ModalHeader>Importar Cluster de Preços</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              {/* <Text marginBottom={6}>Relação de Concorrentes</Text> */}

              <Text fontWeight={'bold'}>IMPORTANTE!</Text>
              <Text>Ao fazer o upload o arquivo atual</Text>
              <Text>será sobrescrito.</Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <label htmlFor="upload">
                  <input
                    type="file"
                    id="upload"
                    name="upload"
                    onChange={onUpload}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement
                      element.value = ''
                    }}
                    style={{ display: 'none' }}
                  />
                  <UploadButton>Carregar Arquivo</UploadButton>
                  <LoadingModal ref={loadingRef} />
                </label>
              </Flex>
              <Divider />
              {/* <Flex w="100%" pt="0.5rem" justifyContent="center">
                <Button width="100%" bg="#2cde8b" onClick={downloadModel}>
                  {isLoading ? (
                    <CircularProgress style={{ color: 'green' }} size={20} />
                  ) : (
                    'Download Planilha Modelo'
                  )}
                </Button>
              </Flex> */}
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalUploadClusterDePrecos
